import { IAscModuleDefinition } from './i-asc-module-definition';
import { DEVELOPMENT_DEFINITION } from './development/development.definition';
import { PASSWORD_MANAGEMENT_DEFINITION } from './password-management/password-management.definition';
import { SUS_PASSWORD_RESET_DEFINITION } from './sus-password-reset/sus-password-reset.definition';
import { NOTIFICATIONS_DEFINITION } from './notifications/notifications.definition';
import { QUICK_TIP_DEFINITION } from './quick-tip/quick-tip.definition';
import { SECURITY_DEFINITION } from './security/security.definition';
import { USER_PROFILES_DEFINITION } from './user-profiles/user-profiles.definition';

export const moduleCatalog: IAscModuleDefinition[] = [
    DEVELOPMENT_DEFINITION,
    NOTIFICATIONS_DEFINITION,
    PASSWORD_MANAGEMENT_DEFINITION,
    QUICK_TIP_DEFINITION,
    SECURITY_DEFINITION,
    SUS_PASSWORD_RESET_DEFINITION,
    USER_PROFILES_DEFINITION
];
